import { Component, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
import { ECameraFunction } from 'src/app/enums/camera-function.enum';
import { CameraService } from 'src/app/providers/camera.service';

/**
 * Representa una caja de información que se muestra en la pantalla de ayuda
 */
@Component({
  selector: 'app-info-box-component',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent
{
  @Input() name: string | undefined;
  @Input() type: string | undefined;

  text:string;
  isOpen = false;

  constructor(private ref:ElementRef,
              private renderer:Renderer2,
              private cameraService:CameraService)
  {
    this.cameraService.onResizeCamera$.subscribe(() => this.setPositions());

    //notificación de que la cámara auxiliar está conectada
    this.cameraService.connectCamera$.subscribe(() =>
    {
      if(this.ref.nativeElement.classList.contains('info-menu'))
        this.renderer.setStyle(this.ref.nativeElement, 'height', '43em');
    });

    //notificación de que la cámara auxiliar está desconectada
    this.cameraService.disconnectCamera$.subscribe(() =>
    {
      if(this.ref.nativeElement.classList.contains('info-menu'))
        this.renderer.setStyle(this.ref.nativeElement, 'height', '24em');
    });
  }

  /**
   * Se dispara cuando se cambia el slider de la caja
   */
  changeViewSlider()
  {
    this.ref.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true, detail: this.name } ));
  }

  /**
   * Se dispara después de que se haya inicializado el componente
   */
  ngAfterViewInit(): void
  {
    if(this.ref.nativeElement.classList.contains('info-menu'))
      this.renderer.setStyle(this.ref.nativeElement, 'height', (!this.cameraService.cameras.find(m => m.function === ECameraFunction.AUXILIAR)) ? '24em' : '43em');

    this.setPositions();
  }

  /**
   * Establece la posición de la caja en la pantalla
   */
  setPositions()
  {
    switch(this.type)
    {
      case 'player':
        const topPlayer = this.getTop('camera_player') - 8;
        this.renderer.setStyle(this.ref.nativeElement, 'top', topPlayer + 'px');
        break;
      case 'info':
        const topInfo = this.getTop('camera_information');
        this.renderer.setStyle(this.ref.nativeElement, 'top', topInfo + 'px');
        this.renderer.setStyle(this.ref.nativeElement, 'display', document.getElementById('camera_information').style.display);
        break;
    }
  }

  /**
   * Obtiene la posición abosoluta de top
   * @param id Identificador del elemento buscado
   * @returns Posición absoluta de top
   */
  getTop(id: string)
  {
    let elem = document.getElementById(id);
    let top = elem.offsetTop;

    do
    {
      elem = elem.parentElement;
      top += elem.offsetTop;
    }
    while(elem.tagName.toLowerCase() != 'ion-content');

    return top;
  }

  /**
   * Se ejecuta cuando se redimensiona la ventana
   */
  @HostListener('window:resize')
  onResize()
  {
    this.setPositions();
  }
}
