import { ElectronService } from 'ngx-electron';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { UsbDevice } from '../models/winco/usb-device.model';
import { EHardwareMode } from '../enums/hardware-mode.enum';
import { DialogBoxData, DialogBoxService, DialogBoxType } from 'dialog-box';
import { DllDevice, Info, InfoVersion } from 'info';
import { Usb } from 'usb';
import { Exit } from 'exit';

/**
 * Gestiona la comunicación con los componentes de Electron
 */
@Injectable({
  providedIn: 'root'
})
export class IpcService
{
  private _window:any; //instancia de window

  info: InfoVersion | undefined;
  drive:UsbDevice | undefined;

  //observables
  private saveFileSubject = new Subject<string>();
  private saveFile$ = this.saveFileSubject.asObservable();

  constructor(private electronService: ElectronService,
              private dialogService: DialogBoxService)
  {
    this._window = window;

    //si es una aplicación Electron añadimos los observables
    //de recepción de eventos
    if(this.electronService.isElectronApp)
    {
      //reciben respuesta de una petición previa
      this._window.api.receive("save-file", (result) =>this.saveFileSubject.next(result));

      //reciben eventos disparados directamente por electron
      this._window.api.receive('usb-added', (device:UsbDevice) => this.drive = device);
      this._window.api.receive('usb-removed', (device:UsbDevice) => this.drive = device);
    }
  }

  /**
   * Comprueba si existe el fichero de seguridad anti-copia del software.
   * Comprueba si existen las carpeta "data", "storage" y sus subcarpetas en la ubicación esperada.
   * Estas carpetas son necesarias para almacenar la base de datos, los vídeos e imágenes que utiliza la aplicación
   * @returns Promise<void>
   */
  initializeData(): Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      //enviamos orden al api para que compruebe si existe la carpeta
      //si no existe se creará
      // this._window.api.send("check-data");
      Info.check(DllDevice.WINCO_PRO).then(async result =>
      {
        this.info = result;

        //obtenemos los dispositivos USB conectados
        this.drive = await Usb.device();

        resolve();
      })
      .catch(err => reject(err));
    });
  }

  /**
   * Cierra la aplicación después de pedir una confirmación por parte del usuario
   */
  async quitApp()
  {
    if(this.info != null)
    {
      if(await Info.isDevelop() || await Usb.isMasterKey())
      {
        Exit.close();
      }
      else
      {
        if(this.info.hardwareMode === EHardwareMode.ALL_IN_ONE)
        {
          Exit.shutdown();
        }
        else
        {
          this.dialogService.show(new DialogBoxData(
            {
              type: DialogBoxType.CONFIRM,
              message: 'recuerda_apagar_equipo',
              acceptCallback: async () => Exit.shutdown()
            }));
        }
      }
    }
    else if(await Usb.isMasterKey())
    {
      Exit.close();
    }
    else
    {
      Exit.shutdown();
    }
  }

  /**
   * Guarda un fichero con los datos de log
   * @param file datos a escribir en el fichero
   * @returns Promise<string>
   */
  saveFile(file:string): Promise<string>
  {
    return new Promise((resolve, reject) =>
    {
      try
      {
        if(this.electronService.isElectronApp)
        {
          //enviamos orden al api para que guarde los datos
          this._window.api.send("save-file", { file: file, drive: (this.drive) ? this.drive.path : 'C:\\' });

          //esperamos respuesta del api
          const subs:Subscription = this.saveFile$.subscribe((result) =>
          {
            subs.unsubscribe();

            //en función de la respuesta del api devolvemos un resultado
            if(result !== 'error' && result !== 'canceled')
              resolve(result);
            else
              reject(result);
          });
        }
        else
        {
          resolve('');
        }
      }
      catch
      {
        reject('error');
      }
    });
  }
}
