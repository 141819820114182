<div class="exercise">
  <section class="info">
    <h3>{{ exercice.name }}</h3>
    <ul class="table">
      <li class="cell">
        <h4>{{ 'objetivo' | translate }}</h4>
        <p>{{ exercice.description }}</p>
      </li>
      <li *ngIf="penalties.length > 0" class="cell">
        <h4>{{ 'penalizaciones' | translate }}</h4>
        <ul class="penalties">
          <li *ngFor="let penalty of penalties">{{ penalty }}</li>
        </ul>
      </li>
      <li *ngIf="equipment.length > 0" class="cell">
        <h4>{{ 'materiales' | translate }}</h4>
        <ul class="equipment">
          <li *ngFor="let equip of equipment">{{ equip }}</li>
        </ul>
      </li>
    </ul>
    <form>
      <img src="./../../../assets/images/SVG/winco_isotipo.svg" />
      <button (click)="hideModal()" class="submit">{{ 'continuar' | translate | uppercase }}</button>
    </form>
  </section>
</div>
