import { AuthWincoService } from 'src/app/providers/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

/**
 * Clase que controla el acceso a la visualización de páginas en función de rol de usuario
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthWincoService) { }

  /**
   * Controla el acceso a una página en función del rol de usuario
   * @param route Ruta a la que se intenta acceder
   * @returns Boolean
   */
  canActivate(route: ActivatedRouteSnapshot)
  {
    //obtenemos el usuario actual
    const user = this.authService.getUser();

    //obtenemos los roles almacenados en la ruta definidos en app-routing.module
    const roles = route.data?.roles || null;

    //si el rol de usuario pertenece a los roles permitidos por la página permitimos el acceso
    if (user && (!roles || roles.indexOf(user.idType) >= 0))
      return true;

    //en caso contrario reenviamos al login
    this.authService.removeUser();
    this.router.navigate(['/', 'account', 'login']);

    return false;
  }
}
