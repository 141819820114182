import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { shareReplay, tap } from 'rxjs/operators';
import { WincoUser } from '../models/winco/user.model';
import { AuthConfig, AuthHttpClientService, AuthService, SimpleResponse } from 'auth';
import { HttpClientService } from './http-client.service';
import { TranslateService } from '@ngx-translate/core';
import { EventManagerService } from 'event-manager';
import { EventManagerEvent } from '../enums/event-manager.enum';

/**
 * Servicio de autenticación de usuarios
 */
@Injectable({
  providedIn: 'root'
})
export class AuthWincoService extends AuthService
{
  constructor(private clientService: HttpClientService,
              translateService:TranslateService,
              httpClientService:AuthHttpClientService,
              eventManagerService: EventManagerService,
              config:AuthConfig)
  {
    super(httpClientService, eventManagerService, config, translateService);

    this.eventManagerService.addEvent<WincoUser>(EventManagerEvent.ENABLE_USER);
  }

  /**
   * Comprueba si existe una cuenta de administrador
   * @returns SimpleResponse - Respuesta del servicio
   */
  public CheckInit(): Promise<SimpleResponse>
  {
    return this.clientService.get<SimpleResponse>("Account/CheckInit");
  }

  /**
   * Refresca el token de autenticación del usuario
   * @param uuid Identificador de usuario
   * @returns User - Datos del usuario
   */
  public RefreshUser(uuid: string): Promise<void>
  {
    return new Promise<void>((resolve) =>
    {
      this.clientService.get<WincoUser>('Account/User/' + uuid).then((response: WincoUser) =>
      {
          this.setUser(response);
          this.enableUser(response);
          resolve();
      });
    });
  }

  /**
   * Crea un nuevo usuario
   * @param data User - Datos de usuario introducidos en el formulario de registro
   * @returns User - Datos del usuario
   */
  public Register(data: WincoUser): Promise<WincoUser>
  {
    return new Promise<WincoUser>((resolve) =>
    {
      this.clientService.post('Account/Register', data).then((response: WincoUser) =>
      {
          this.setUser(response);
          this.enableUser(response);
          resolve(response);
      });
    });
  }

  /**
   * Autoriza a un usuario
   * @param user usuario
   */
  enableUser(user)
  {
    this.eventManagerService.emitEvent(EventManagerEvent.ENABLE_USER, user);
  }

  /**
 * Obtiene del localStorage el usuario
 * @returns El usuario
 */
  public override getUser()
  {
    return (sessionStorage.getItem('winco.user')) ? new WincoUser(JSON.parse(sessionStorage.getItem('winco.user'))) : null;
  }
}
