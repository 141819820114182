import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExerciceCounterComponentModule } from '../exercice-counter/exercice-counter.component.module';
import { ExerciseTrackingComponent } from './exercise-tracking.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { FormElementsModule } from 'form-elements';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    FormElementsModule,
    ExerciceCounterComponentModule
  ],
  exports: [ExerciseTrackingComponent],
  declarations: [ExerciseTrackingComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ExerciceTrackingComponentModule {}
