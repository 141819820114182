import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExerciceCounterComponent } from './exercice-counter.component';


@NgModule({
  imports: [
    CommonModule
  ],
  exports: [ExerciceCounterComponent],
  declarations: [ExerciceCounterComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ExerciceCounterComponentModule {}
