<div *ngIf="mode == 'counter'">
  <a class="plus-button" (click)="subtract()"><img src="./../../../assets/images/SVG/icon_miniminus_white_1.svg" /></a>
  <input class="counter-box" type="text" [attr.value]="show" readonly (dblclick)="changeView('free')">
  <a class="plus-button" (click)="sum()"><img src="./../../../assets/images/SVG/icon_miniplus_white_1.svg" /></a>
</div>

<div *ngIf="mode == 'free'">
  <span *ngIf="type == 'time'">
    <input class="free-box-time" type="text" [attr.value]="minutes" maxlength="2" (keyup)="keyUpBox($event, 'minutes-box')" #minutesBox>
    <span>:</span>
    <input class="free-box-time" type="text" [attr.value]="seconds" maxlength="2" (keyup)="keyUpBox($event, 'seconds-box')" #secondsBox>
  </span>
  <input class="free-box" *ngIf="type != 'time'" type="text" [attr.value]="show" maxlength="4" (keyup)="keyUpBox($event, 'free-box')">
  <a class="change-button" (click)="changeView('counter')"><img src="./../../../assets/images/SVG/icon_history_black.svg" /></a>
</div>
