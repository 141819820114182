import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthWincoService } from '../providers/auth.service';

/**
 * Interceptor de errores en una petición al web service local
 * Esta clase revisa y modifica todas las peticiones que se hacen al web service local antes de enviarlas añadiendo un manejador de errores
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorsInterceptor implements HttpInterceptor {

  constructor(private authService: AuthWincoService,
              private router:Router) { }


  /**
   * Intercepta una petición http
   * @param request Petición interceptada
   * @param next Manejador de evento
   * @returns Observable
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    //añadimos un manejador de eventos para la respuesta de la petición
    return next.handle(request).pipe
    (
      //capturamos el posible error
      catchError((err) =>
      {
        //En función del tipo de error damos distintas respuestas
        if(err.status == 400)
        {
          //bad request
          //lanzamos el error para que sea capturado por el código emisor de la petición
          return throwError(err.error);
        }
        else if (err.status == 401 || err.status === 403)
        {
          //acceso_denegado
          //eliminamos el acceso del usuario y lo enviamos a la página de login
          this.authService.removeUser();
          this.router.navigate(['/', 'account', 'login']);
        }
        else if (err.status == 404)
        {
          //not found
          //mostramos la pantalla de error con el mensaje página no encontrada
          this.router.navigate(['/', 'errors', 'error', '404']);
        }
        else if(err.status == 0)
        {
          //sin conexión
          //mostramos la pantalla de error con el mensaje sin conexión
          this.router.navigate(['/', 'errors', 'error', '0']);
        }
        else if(request.url.toLowerCase().indexOf('stopanalysis') >= 0)
        {
          //esto es un parche para evitar los errores producidos por la librería de Nuavis
          //cuando se produce un error de lectura y reinicia el servidor
          this.router.navigate(['/', 'errors', 'error', '1001']);
        }
        else
        {
          //error inesperado
          //mostramos la pantalla de error con el mensaje error inesperado
          this.router.navigate(['/', 'errors', 'error', '500']);
        }

        //finalizamos el ciclo de vida del error
        return throwError(of(false));
      })
    );
  }
}
