<ion-app [ngClass]="isInitPage ? 'white': 'black'">
  <app-best-exercice-detail-component *ngIf="showExerciceDetail"
    (close)="closeExerciceDetail()"
    #exerciceDetail>
  </app-best-exercice-detail-component>

  <ion-content [ngClass]="isInitPage ? 'white': 'black'">
    <div *ngIf="aside==EViewerAsideEnum.HELP" class="cont-info">
      <a class="close-info" (click)="changeRightContent(EViewerAsideEnum.CLOSE_HELP)">
        <img src="./../assets/images/SVG/close_light.svg" />
        {{ 'cerrar' | translate }}
      </a>
      <app-info-box-component *ngFor="let infoBox of helpViewer"
        [name]="infoBox.name"
        [type]="infoBox.type"
        [class]="infoBox.class"
        (change)="changeViewSlider($event)">
      </app-info-box-component>
    </div>

    <header id="main_header" *ngIf="!isInitPage && !isExercicePage">
        <img src="../../assets/images/SVG/logo_winco_horizontal.svg" class="main-logo">

        <!-- <div *ngIf="!isMenuPage" class="access">
          <img src="../../assets/images/SVG/icon_user.svg">
          <span>{{ 'acceder' | translate }}</span>
        </div> -->

        <div class="menu">
          <div class="cont-menu">
            <span *ngIf="!hasMenuAdmin">&nbsp;</span>
            <nav *ngIf="hasMenuAdmin">
              <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER])" routerLink="/administration/groups/list" [ngClass]="{'selected' : getMenuSelected('groups') }">
                <span class="radio"></span>
                <span class="name">{{ 'grupos' | translate }}</span>
              </a>
              <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN])" routerLink="/administration/teachers/list" [ngClass]="{'selected' : getMenuSelected('teachers') }">
                <span class="radio"></span>
                <span class="name">{{ 'profesores' | translate }}</span>
              </a>
              <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER])" routerLink="/administration/students/list" [ngClass]="{'selected' : getMenuSelected('students') }">
                <span class="radio"></span>
                <span class="name">{{ 'alumnos' | translate }}</span>
              </a>
              <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER])" routerLink="/administration/exercices/list" [ngClass]="{'selected' : getMenuSelected('exercices') }">
                <span class="radio"></span>
                <span class="name">{{ 'ejercicios' | translate }}</span>
              </a>
              <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER])" routerLink="/administration/courses/list" [ngClass]="{'selected' : getMenuSelected('courses') }">
                <span class="radio"></span>
                <span class="name">{{ 'cursos' | translate }}</span>
              </a>
            </nav>
          </div>

          <div *ngIf="isMenuPage && userName" class="user">
            <div class="ic-user">{{ userAcronym }}</div>
            <div class="txt-user">
              <span>{{ 'bienvenido' | translate }}</span>
              <span>{{ userName }}</span>
            </div>
          </div>

          <a class="log-out" (click)="exit()">
            <img src="./../../assets/images/SVG/close_light.svg">
          </a>

        </div>
    </header>


    <nav *ngIf="!isInitPage && !isExercicePage && hasMenuNav" class="menu-left">
      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER])" routerLink="/administration/groups/list">
        <img [src]="'../../assets/images/SVG/icon_list_' + (getMenuSelected('administration') ? 'black' : 'white') + '.svg'">
        <label>{{ 'administracion' | translate }}</label>
      </a>

      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT])" routerLink="/profile">
        <img [src]="'../../../assets/images/SVG/icon_perfil_' + (getMenuSelected('profile') ? 'black' : 'white') + '.svg'">
        <label>{{ 'mi_perfil' | translate }}</label>
      </a>

      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT])" routerLink="/update">
        <img [src]="'../../assets/images/SVG/icon_update_' + (getMenuSelected('update') ? 'black' : 'white') + '.svg'">
        <label>{{ 'actualizar' | translate }}</label>
      </a>

      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT])" routerLink="/export">
        <img [src]="'../../assets/images/SVG/ixon_export_' + (getMenuSelected('export') ? 'black' : 'white') + '.svg'">
        <label>{{ 'exportar' | translate }}</label>
      </a>

      <!-- <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER])" routerLink="/online/rooms">
        <img [src]="'../../assets/images/SVG/icon_gride_' + (getMenuSelected('online') ? 'black' : 'white') + '.svg'">
        <label>{{ 'formacion_online' | translate }}</label>
      </a> -->

      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT])" routerLink="/history/history">
        <img [src]="'../../../assets/images/SVG/icon_history_' + (getMenuSelected('history') ? 'black' : 'white') + '_1.svg'">
        <label>{{ 'historico' | translate }}</label>
      </a>

      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT])" routerLink="/statistics">
        <img [src]="'../../assets/images/SVG/icon_graf_' + (getMenuSelected('statistics') ? 'black' : 'white') + '.svg'">
        <label>{{ 'estadisticas' | translate }}</label>
      </a>

      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT])" routerLink="/courses/list">
        <img [src]="'../../../assets/images/SVG/icon_cursos_' + (getMenuSelected('courses', 'administration') ? 'black' : 'white') + '.svg'">
        <label>{{ 'cursos' | translate }}</label>
      </a>

      <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT])" routerLink="/exercice/type">
        <img [src]="'../../../assets/images/SVG/icon_formas_' + (getMenuSelected('exercice') ? 'black' : 'white') + '.svg'">
        <label>{{ 'entrenamiento' | translate }}</label>
      </a>

      <a (click)="logOut()">
        <img [src]="'../../../assets/images/SVG/icon_logout_white.svg'">
        <label>{{ 'cerrar_sesion' | translate }}</label>
      </a>
    </nav>

    <nav *ngIf="!isInitPage && isExercicePage && hasMenuNav" class="menu-left menu-left-exercice">
      <a (click)="goOut()">
        <img src="../../../assets/images/SVG/icon_on_red.svg">
        <label>{{ 'cancelar_entrenamiento' | translate }}</label>
      </a>

      <div class="separator" *ngIf="getMenuSelected('exercice/viewer') && cameraService.hasMultiCamera"></div>

      <a *ngIf="getMenuSelected('exercice/viewer') && cameraService.hasMultiCamera" (click)="changeCamera(ECamera.SYSTEM)">
        <img [src]="'../../../assets/images/SVG/cam_1_' + (cameraService.camera == ECamera.SYSTEM ? 'white' : 'grey') + '.svg'">
        <label>{{ 'camara_principal' | translate }}</label>
      </a>

      <a *ngIf="getMenuSelected('exercice/viewer') && cameraService.hasMultiCamera" (click)="changeCamera(ECamera.AUXILIAR)">
        <img [src]="'../../../assets/images/SVG/cam_2_' + (cameraService.camera == ECamera.AUXILIAR ? 'white' : 'grey') + '.svg'">
        <label>{{ 'camara_auxiliar' | translate }}</label>
      </a>

      <a *ngIf="getMenuSelected('exercice/viewer') && cameraService.hasMultiCamera" (click)="changeCamera(ECamera.BOTH)">
        <img [src]="'../../../assets/images/SVG/cam_doble_' + (cameraService.camera == ECamera.BOTH ? 'white' : 'grey') + '.svg'">
        <label>{{ 'ambas_camaras' | translate }}</label>
      </a>

      <div class="separator" *ngIf="getMenuSelected('exercice/viewer') && cameraService.hasMultiCamera"></div>

      <a *ngIf="getMenuSelected('exercice/viewer') && hasVideoButton" (click)="changeRightContent(EViewerAsideEnum.VIDEO)">
        <img [src]="'../../../assets/images/SVG/icon_video_' + (aside == EViewerAsideEnum.VIDEO ? 'white' : 'grey') + '.svg'">
        <label>{{ 'video_explicativo' | translate }}</label>
      </a>

      <!-- <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT]) && getMenuSelected('exercice/viewer') && hasVideoButton" (click)="changeRightContent(EViewerAsideEnum.ZOOM)">
        <img [src]="'../../../assets/images/SVG/icon_zoom_' + (aside == EViewerAsideEnum.ZOOM ? 'white' : 'grey') + '.svg'">
      </a> -->
      <a *ngIf="getMenuSelected('exercice/viewer')" (click)="changeRightContent(EViewerAsideEnum.GUIDE)">
        <img [src]="'../../../assets/images/SVG/icon_bullets_' + (aside == EViewerAsideEnum.GUIDE ? 'white' : 'grey') + '.svg'">
        <label>{{ 'guia' | translate }}</label>
      </a>
      <!-- <a *ngIf="hasPermissions([ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT]) && getMenuSelected('exercice/viewer')" (click)="changeRightContent(EViewerAsideEnum.CHAT)">
        <img [src]="'../../../assets/images/SVG/icon_chat_' + (aside == EViewerAsideEnum.CHAT ? 'white' : 'grey') + '.svg'">
      </a> -->
      <a *ngIf="getMenuSelected('exercice/viewer')" (click)="changeRightContent(EViewerAsideEnum.HELP)">
        <img [src]="'../../../assets/images/SVG/icon_help_' + (aside == EViewerAsideEnum.HELP ? 'white' : 'grey') + '.svg'">
        <label>{{ 'ayuda' | translate }}</label>
      </a>
      <!-- <a  (click)="logOut()">
        <img [src]="'../../../assets/images/SVG/icon_logout_grey.svg'">
      </a> -->
    </nav>

    <ion-router-outlet></ion-router-outlet>

    <footer id="main_footer" *ngIf="hasFooter && !isExercicePage" [ngClass]="{ 'footer-line': typeFooter == TypeFooterEnum.LINE }">
      <div class="language-selector">
        <a class="selector selector-version" (click)="showInfoVersion()">
          <span>?</span>
        </a>
      </div>
      <div class="language-selector">
        <a class="selector" (click)="toggleLanguages($event)">
          <span>{{ nameLanguage }}</span>
          <img src="../../assets/images/SVG/icon_down_white_2.svg" class="arrow-down">
        </a>

        <ul class="languages" *ngIf="showLanguages" (click)="toggleLanguages($event)">
          <li *ngFor="let item of availableLanguages">
            <a class="selector" *ngIf="item.enabled" (click)="selectLanguage($event, item.code)"><span>{{ item.code | uppercase }}</span></a>
          </li>
        </ul>

        <span class="footer-claim">{{ anno }} {{ 'derechos_reservados' | translate }}</span>
      </div>

      <img src="../../assets/images/SVG/logo_medical_blanco.svg" class="logo-medical">
    </footer>

    <footer *ngIf="hasFooter && isExercicePage" class="black-footer">
      <ul *ngIf="userName">
        <li>
          <div class="icon icon-user"><span>{{ userAcronym }}</span></div>
          <div class="text">
            <span>{{ userName }}</span>
          </div>
        </li>
        <li>
          <div class="icon icon-image"><img src="./../assets/images/SVG/icon_clock_white.svg"></div>
          <div class="text">
            <span>{{ 'mejor_tiempo' | translate }}</span>
            <span>{{ betterTime }} min.</span>
          </div>
        </li>
        <li *ngIf="trackingActive">
          <div class="icon icon-image"><img src="./../assets/images/SVG/icon_dots_white.svg"></div>
          <div class="text">
            <span>{{ 'mejor_puntuacion' | translate }}</span>
            <span>{{ betterScore }}%</span>
          </div>
        </li>
      </ul>
      <form *ngIf="showResultExerciceButtons" class="buttons">
        <button (click)="goOut()" class="cancel">{{ 'salir' | translate | uppercase }}</button>
        <button (click)="repeatExercice()" class="submit">{{ 'repetir' | translate | uppercase }}</button>
      </form>
    </footer>

  </ion-content>
</ion-app>
