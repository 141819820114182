import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

/**
 * Servicio asociado al componente Video.
 */
@Injectable({
  providedIn: 'root'
})
export class VideoService
{
  //observables
  private stopSubject = new Subject<void>();

  stop$ = this.stopSubject.asObservable();

  /**
   * Dispara un evento global para informar de que una reproducción de vídeo debe ser detenida
   */
  stopVideo()
  {
    this.stopSubject.next()
  }
}
