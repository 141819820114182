import { TranslateModule } from '@ngx-translate/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBoxComponent } from './info-box.component';
import { SwiperModule } from 'swiper/angular';
// import SwiperCore, { Pagination } from 'swiper/core';
// import { IonicSwiper } from '@ionic/angular';

// SwiperCore.use([Pagination]);

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SwiperModule
  ],
  exports: [InfoBoxComponent],
  declarations: [InfoBoxComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class InfoBoxComponentModule {}
