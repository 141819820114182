
<div class="tracking-container">
  <span>{{ 'realizar_tracking' | translate }}</span>
  <lib-form-checkbox
    *ngIf="this.exerciceService.trackingCheck==false"
    [value]="exerciceService.trackingCheck"
    (change)="onChange(true)"
    class="check-off">
  </lib-form-checkbox>
  <lib-form-checkbox
    *ngIf="this.exerciceService.trackingCheck==true"
    [value]="exerciceService.trackingCheck"
    (change)="onChange(false)"
    class="check-on">
</lib-form-checkbox>
</div>


