export const environment = {
  production: true,
  defaultLanguage: 'es',

  // endPointService: "http://localhost:8080",
  // endPointStorageService: "https://storage.idimedical.com",
  // environmentStorageService: "prod",
  // numCameras: 2,

  endPointService: "https://api.winco.dev.idimedical.com",
  endPointStorageService: "https://storage.idimedical.com",
  environmentStorageService: "dev",
  numCameras: 1,

  regPerPage: 100000,
  guidEmpty: '00000000-0000-0000-0000-000000000000',
  product: "05BCDC1B-6892-4ED0-942D-17E3F9DC5B94",
  delayOperation: 100
};
