<div>
  <img class="close" (click)="close()" src="./../../../assets/images/SVG/close_grey.svg" />
  <ul class="result">
    <li>
      <h3>{{ userName }}</h3>

      <ul class="score">
        <li>
          <img src="./../../../../assets/images/SVG/icon_clock_black.svg" />
          <p class="score_number">{{ exerciceService.GetFormatTime(time) }}</p>
          <p class="score_title">{{ 'tiempo' | translate | uppercase }}</p>
        </li>
        <li>
          <img src="./../../../../assets/images/SVG/icon_clock_black.svg" />
          <p class="score_number" [ngClass]="{
            'bad': score <= 25,
            'regular' : score > 25 && score <= 50,
            'good': score > 50 && score <= 75,
            'excelent' : score > 75
          }">{{ score }}<small>%</small></p>
          <p class="score_title">{{ 'puntuacion' | translate | uppercase }}</p>
        </li>
      </ul>

      <h3 class="underline">{{ 'resultados' | translate }}</h3>
      <ul class="params">
        <li *ngFor="let param of params">
          <span class="parameter" innerHtml="{{ param.title | translate }}"></span>
          <span class="value"><strong>{{ param.valueText }}</strong></span>
          <span class="unit {{ param.css }}" innerHtml="{{ param.unit }}"></span>
        </li>
      </ul>
    </li>
    <li>
      <app-video-component [poster]="urlPoster" [video]="urlVideo" #video></app-video-component>
    </li>
  </ul>
</div>
