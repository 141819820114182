import { Injectable } from '@angular/core';
import { EventManagerService } from 'event-manager';
import { HttpManagerClientService, HttpManagerService } from 'http-manager';
import { environment } from 'src/environments/environment';

/**
 * Gestiona las conexiones http a un web services a traves de la clase heredada HttpManagerClientService
 */
@Injectable({
  providedIn: 'root'
})
export class HttpClientService extends HttpManagerClientService
{
  constructor(httpManagerService: HttpManagerService,
    eventManagerService: EventManagerService)
  {
    super(httpManagerService, eventManagerService);
    this.setEndPoint(environment.endPointService);
  }
}
