
  <a (click)="changeViewSlider()">
    <img *ngIf="!isOpen" src="../../../assets/images/SVG/icon_miniplus_black.svg">
    <img *ngIf="isOpen" src="../../../assets/images/SVG/icon_miniminus_black.svg">
  </a>

  <div *ngIf="isOpen" class="slider">
    <ul *ngIf="name === 'infoMenu'">
      <li>{{ 'desde_aqui_controlar' | translate }}</li>
      <li><img src="./../../../assets/images/SVG/icon_video_grey.svg"> <span>{{ 'seleccion_camara' | translate }}</span></li>
      <li><img src="./../../../assets/images/SVG/icon_bullets_grey.svg"> <span>{{ 'contenido_lateral_derecho' | translate }}</span></li>
      <li><img src="./../../../assets/images/SVG/icon_help_grey.svg"> <span>{{ 'activar_ayuda' | translate }}</span></li>
      <li><img src="./../../../assets/images/SVG/icon_on_red.svg"> <span>{{ 'salir_ejercicio' | translate }}</span></li>
    </ul>
    <div *ngIf="name === 'infoInstructions'">{{ 'area_informacion' | translate }}</div>
    <div *ngIf="name === 'infoVideo'">{{ 'area_mensajes' | translate }}</div>
    <div *ngIf="name === 'infoPlayer'">{{ 'area_linea_tiempo' | translate }}</div>
    <div *ngIf="name === 'infoTimer'">{{ 'area_cronometro' | translate }}</div>
  </div>
