import { ECameraFunction } from "src/app/enums/camera-function.enum";
import { ECamera } from "src/app/enums/camera.enum";

export class Camera
{
  idDevice: string | undefined;
  type: ECamera | undefined;
  function: ECameraFunction | undefined;
  active: boolean | undefined;

  constructor(data: any) {
    Object.assign(this, data);
  }
}
