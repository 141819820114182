import { VideoService } from './../../providers/video.service';
import { Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Representa un reproductor vídeo
 */
@Component({
  selector: 'app-video-component',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit
{
  environment = environment;

  @Input() poster: string | undefined;
  @Input() video: string | undefined;

  @ViewChild('videoElement') videoElement : ElementRef;

  constructor(private videoService: VideoService) { }

  /**
   * Se ejecuta cuando se inicializa el componente
   */
  ngOnInit(): void
  {
    this.videoService.stop$.subscribe(() => this.stop());
  }

  /**
   * Reproduce el vídeo
   */
  play()
  {
    if(this.videoElement)
      this.videoElement.nativeElement.play();
  }

  /**
   * Detiene la reproducción de vídeo
   */
  stop()
  {
    if(this.videoElement)
      this.videoElement.nativeElement.pause();
  }
}
