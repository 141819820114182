import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExerciceInfoComponent } from './exercice-info.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [ExerciceInfoComponent],
  declarations: [ExerciceInfoComponent]
})
export class ExerciceInfoComponentModule {}
