<video *ngIf="video" #videoElement
  controls
  [attr.poster]="environment.endPointService + '/' + poster"
  [attr.src]="environment.endPointService + '/' + video">
</video>

<div *ngIf="!video" class="no_video">
  <img src="./../../../assets/images/SVG/icon_no_play_black.svg">
  <p>{{ 'video_no_disponible' | translate | uppercase }}</p>
</div>

<!-- <div class="player" [ngClass]="{ 'expanded': expanded }">
  <a *ngIf="!isPlaying" class="cell icon-play" (click)="play()">
    <img src="./../../../../assets/images/SVG/icon_play_grey.svg">
  </a>

  <a *ngIf="isPlaying" class="cell icon-play" (click)="pause()">
    <img src="./../../../../assets/images/SVG/icon_pause_grey.svg">
  </a>

  <div class="cell time-line" [ngClass]="{ 'expanded': expanded }" (click)="onClick($event)">
    <span class="bottom left">00:00</span>
    <span class="bottom right">00:58</span>

    <div class="progress-bar">
      <div class="progress" #progress></div>
    </div>
  </div>

  <a *ngIf="expanded" class="cell icon-play" (click)="hide()">
      <img src="./../../../../assets/images/SVG/icon_zoomout_grey.svg">
  </a>
</div> -->
