import { Injectable } from '@angular/core';
import { ExerciceType } from '../models/winco/exercice-type.model';
import { Replay } from '../models/winco/replay.model';
import { EViewerAside } from '../enums/viewer-aside.enum';
import { BetterScore } from '../models/winco/better-score.model';
import { HttpClientService } from './http-client.service';
import { EventManagerService } from 'event-manager';
import { EventManagerEvent } from '../enums/event-manager.enum';

/**
 * Servicio que gestiona la reproducción de un ejercicio y su interacción con el usuario
 */
@Injectable({
  providedIn: 'root'
})
export class ExerciceService
{

  trackingCheck: boolean = true;
  exerciseTimeWhithoutTracking: any;

  constructor(private clientService: HttpClientService,
              private eventManagerService: EventManagerService
  )
  {
    this.eventManagerService.addEvent<EViewerAside>(EventManagerEvent.SET_ASIDE);
    this.eventManagerService.addEvent<EViewerAside>(EventManagerEvent.SET_BUTTONS);
    this.eventManagerService.addEvent<boolean>(EventManagerEvent.SHOW_VIDEO_BUTTON);
    this.eventManagerService.addEvent<BetterScore>(EventManagerEvent.ON_UPDATE_BETTER);
    this.eventManagerService.addEvent<any>(EventManagerEvent.SHOW_BEST_EXERCISE);
    this.eventManagerService.addEvent<void>(EventManagerEvent.HIDE_BEST_EXERCISE);
    this.eventManagerService.addEvent<string[]>(EventManagerEvent.CLOSE_EXERCISE);
    this.eventManagerService.addEvent<boolean>(EventManagerEvent.START_EXERCISE);
    this.eventManagerService.addEvent<boolean>(EventManagerEvent.TRACKING_CHECK);
    this.eventManagerService.addEvent<void>(EventManagerEvent.GET_BETTER_SCORE);

  }

  /**
   * Convierte un número a formato minutos/segundos (00:00)
   * @param time Número que debe ser convertido
   * @returns Cadena de texto formateada
   */
  GetFormatTime(time: number)
  {
    if(time)
    {
      const minutes = Math.floor(time / 60),
            seconds = time % 60;

      return ((minutes < 10) ? '0' + minutes : minutes) + ':' + ((seconds < 10) ? '0' + seconds : seconds);
    }
    else
    {
      return '00:00';
    }
  }

  /**
   * Elimina los elementos visuales que componen un ejercicio
   * @param exception Opcional. Componente que debe quedar exento de ser eliminado
   */
  public ClearExercice(exception:string = '')
  {
    const exercices = document.getElementsByTagName('app-exercice');
    const statistics = document.getElementsByTagName('app-statistics');

    if(exception != 'viewer')
      for (let i = 0; i < exercices.length; i++)
        exercices[i].parentElement.removeChild(exercices[i]);

    if(exception != 'statistics')
      for (let i = 0; i < statistics.length; i++)
        statistics[i].parentElement.removeChild(statistics[i]);
  }

  /**
   * Elimina los elementos visuales que componen un formulario de edición de ejercicio
   * @param exception Opcional. Componente que debe quedar exento de ser eliminado
   */
  public ClearForms(exception:string = '')
  {
    const list = document.getElementsByTagName('app-list');
    const edit = document.getElementsByTagName('app-edit');

    if(exception != 'list')
      for (let i = 0; i < list.length; i++)
        list[i].parentElement.removeChild(list[i]);

    if(exception != 'edit')
      for (let i = 0; i < edit.length; i++)
        edit[i].parentElement.removeChild(edit[i]);
  }

  /**
   * Obtiene la mejor puntuación de un usuario para un ejercicio
   * @param idExercice Identificador de ejercicio
   * @param idUser Identificador de usuario
   * @returns BetterScore - Datos de la puntuación
   */
  public GetBetterScore(idExercice: string, idUser: string): Promise<BetterScore>
  {
    return this.clientService.get<BetterScore>("Exercices/BetterScore/" + idExercice + '/' + idUser);
  }

  /**
   * Obtiene la lista de tipos de ejercicio
   * @returns ExerciceType[] - Lista de tipos de ejercicio
   */
  public GetExerciceType(): Promise<ExerciceType[]>
  {
    return this.clientService.get<ExerciceType[]>("Exercices/ExercicesTypes");
  }

  /**
   * Guarda los datos de un intento de usuario
   * @param data Datos del intento
   * @returns Replay - Datos del intento actualizados
   */
  public SaveReplay(data: Replay): Promise<Replay>
  {
    return this.clientService.post<Replay>("Replays/SaveData", data);
  }
}
