import { User } from 'auth';
import { ETypeUser } from './../../enums/type-user.enum';
import { GroupUser } from "./group-user.model";
import { Group } from "./group.model";
import { UserCategory } from "./user-category.model";
import { UserSpeciality } from "./user-speciality.model";

export class WincoUser extends User
{
  password: string | undefined;
  repeatPassword: string | undefined;

  userSpecialityItem: UserSpeciality| undefined;
  userCategoryItem: UserCategory| undefined;

  categoryName: string | undefined;
  specialityName: string | undefined;

  groupUsers: GroupUser[]| undefined;
  groupsAdded: GroupUser[]| undefined;
  groupList: Group[] | undefined;
  selectedGroup: boolean | undefined;

  idType:ETypeUser | undefined;

  expanded: boolean | undefined;

  constructor(data: any) {
    super(data);
    Object.assign(this, data);
  }
}
