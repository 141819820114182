import { VideoComponentModule } from './../video/video.component.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BestExerciceDetailComponent } from './best-exercice-detail.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    VideoComponentModule
  ],
  exports: [BestExerciceDetailComponent],
  declarations: [BestExerciceDetailComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class BestExerciceDetailComponentModule {}
