import { BestExerciceDetailComponentModule } from './components/best-exercice-detail/best-exercice-detail.component.module';
import { InfoBoxComponentModule } from './components/info-box/info-box.component.module';
import { VideoComponentModule } from 'src/app/components/video/video.component.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorsInterceptor } from './interceptors/errors.interceptor';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpManagerConfig, HttpManagerModule } from 'http-manager';

import { ElectronService } from 'ngx-electron';
import { ModalModule } from 'modal';
import { LoadingConfig, LoadingModule, LoadingErrorsInterceptor, LoadingRequestInterceptor } from 'loading';

import { environment } from 'src/environments/environment';
import { AuthModule, AuthConfig } from 'auth';
import { CrudManagerModule } from 'crud-manager';
import { DialogBoxConfig, DialogBoxModule } from 'dialog-box';
import { ModalBoxModule, ModalBoxButton, ModalBoxEvent, ModalBoxClose } from 'modal-box';
import { VersionBoxConfig, VersionBoxModule } from 'version-box';
import { LanguageTabModule, LanguageTabConfig } from 'language-tab';
import { HttpStorageConfig, HttpStorageModule } from 'http-storage';
import { ExerciceInfoComponentModule } from './components/exercice-info/exercice-info.component.module';
import { global } from 'common';
import { ExerciceTrackingComponentModule } from './components/exercise-tracking/exercise-tracking.component.module';

global.init();

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
            IonicModule.forRoot({animated: false}),
            HttpClientModule,
            AppRoutingModule,
            FormsModule,
            ReactiveFormsModule,
            VideoComponentModule,
            InfoBoxComponentModule,
            BestExerciceDetailComponentModule,
            VideoComponentModule,
            ExerciceInfoComponentModule,
            ExerciceTrackingComponentModule,
            ModalBoxModule.forRoot(),

            TranslateModule.forRoot({
              defaultLanguage: environment.defaultLanguage,
              loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [/*HttpClient,*/ HttpBackend]
              }
            }),
            LanguageTabModule.forRoot(new LanguageTabConfig({
              endPointService: environment.endPointService,
              activeLanguage: environment.defaultLanguage,
              defaultLanguage:'es',
              localStorageLanguage: 'winco.language'
            })),
            NoopAnimationsModule,
            ModalModule.forRoot(),
            LoadingModule.forRoot(new LoadingConfig({img: "./../assets/images/loader.gif"})),
            AuthModule.forRoot(new AuthConfig({
              endPointService: environment.endPointService,
              localStorageAuthToken: 'winco.aut_token',
              sessionStorageUser: 'winco.user',
              localStorageRemember: 'winco.remember'
            })),
            HttpManagerModule.forRoot(new HttpManagerConfig({
              defaultLanguage: environment.defaultLanguage,
              product: environment.product
            })),
            VersionBoxModule.forRoot(new VersionBoxConfig({
              image: "./../assets/images/SVG/winco_isotipo.svg",
              close: new ModalBoxClose({
                event: ModalBoxEvent.CANCEL
              })
            })),
            CrudManagerModule.forRoot(),
            DialogBoxModule.forRoot(new DialogBoxConfig({
              accept: new ModalBoxButton({
                title: 'aceptar',
                event: ModalBoxEvent.ACCEPT
              }),
              cancel: new ModalBoxButton({
                title: 'cancelar',
                event: ModalBoxEvent.CANCEL
              }),
              close: new ModalBoxClose({
                event: ModalBoxEvent.CANCEL
              })
            })),
            HttpStorageModule.forRoot(new HttpStorageConfig({
              endPointService: environment.endPointStorageService,
              environment: environment.environmentStorageService
            }))
          ],
  providers: [
    ElectronService,
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}

// export function HttpLoaderFactory(http: HttpClient)
// {
//   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
// }

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
     { prefix: './assets/i18n/', suffix: '.json' },//host app i18n assets
     { prefix: './auth/assets/i18n/', suffix: '.json' }, //auth assets
     { prefix: './modal-box/assets/i18n/', suffix: '.json' }, //modal-box assets
     { prefix: './version-box/assets/i18n/', suffix: '.json' } //version-box assets
   ])
 }
