import { Component } from '@angular/core';
import { EventManagerService } from 'event-manager';
import { ModalBoxService, ModalBoxEvent } from 'modal-box';
import { EventManagerEvent } from 'src/app/enums/event-manager.enum';
import { ExerciceService } from 'src/app/providers/exercice.service';

/**
 * Componente que se muestra en el modal para activar o desactivar el tracking en los ejercicios
 */
@Component({
  selector: 'app-exercise-tracking',
  templateUrl: './exercise-tracking.component.html',
  styleUrls: ['./exercise-tracking.component.scss']
})
export class ExerciseTrackingComponent {

  ModalBoxEvent = ModalBoxEvent;

  showComponent: boolean = true;

  constructor(private eventManagerService: EventManagerService,
              public modalBoxService: ModalBoxService,
              public exerciceService:ExerciceService)
  {}

  /**
   * Se ejecuta al inicio del componente y reestablece el valor del tracking
   */
  ngOnInit()
  {
    this.exerciceService.trackingCheck = true;
    this.eventManagerService.emitEvent(EventManagerEvent.TRACKING_CHECK, true);
  }

  /**
   * Guarda el valor del check de tracking en el servicio
   * @param value Valor a pasar del check
   */
  onChange(value: boolean)
  {
    this.exerciceService.trackingCheck = value;
    this.eventManagerService.emitEvent(EventManagerEvent.TRACKING_CHECK, value);
  }
}
