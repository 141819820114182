import { Exercice } from 'src/app/models/winco/exercice.model';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalBoxEvent, ModalBoxService } from 'modal-box';
import { EventManagerService } from 'event-manager';
import { CameraService } from 'src/app/providers/camera.service';

/**
 * Muestra una ventana de ejercicio
 */
@Component({
  selector: 'app-exercice-info-component',
  templateUrl: './exercice-info.component.html',
  styleUrls: ['./exercice-info.component.scss'],
})
export class ExerciceInfoComponent implements OnInit
{
  ModalBoxEvent = ModalBoxEvent;
  environment = environment;

  @Input() message: string;

  exercice:Exercice;
  penalties:string[];
  equipment:string[];

  constructor(private cameraService: CameraService,
              public modalBoxService:ModalBoxService,
              public eventManagerService: EventManagerService
  )
  {}

  /**
   * Se ejecuta la primera vez que se carga el componente
   */
  ngOnInit()
  {
    this.exercice = this.modalBoxService.config.data;
    this.equipment = new Array<string>();
    this.penalties = new Array<string>();

    if(this.exercice)
    {
      if(this.exercice.equipment)
        this.equipment = this.exercice.equipment.split('\n');

      if(this.exercice.penalties)
        this.penalties = this.exercice.penalties.split('\n');
    }
  }

  /**
   * Oculta el modal de informacion y guarda la variable que comprueba si se esta mostrando
   */
  hideModal () {
    this.cameraService.showingInfo = false
    this.modalBoxService.hide();
  }
}
