import { ECamera } from './../enums/camera.enum';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Replay } from '../models/winco/replay.model';
import { environment } from 'src/environments/environment';
import { Camera } from '../models/winco/camera.model';
import { ECameraFunction } from '../enums/camera-function.enum';
import { HttpClientService } from './http-client.service';

/**
 * Servicio que da soporte al componente Camera
 * Se encarga de gestionar los procesos de las cámaras de visualización (principal y auxiliar)
 */
@Injectable({
  providedIn: 'root'
})
export class CameraService
{
  private stream:MediaStream; //stream de video que se está reproduciendo actualmente

  //configuración por defecto de la cámara principal
  private mainConstraints =
  {
    audio: false,
    video: {
      width: { ideal: 3564 },
      height: { ideal: 2448 },
      deviceId: null
    }
  };

  public cameras: Camera[];

  //identificador de la cámara auxiliar
  //public auxiliarCamera: string;

  public systemCamerasConnected = false;
  public streamStarted = false;

  //variables para el control de reproducción
  public hasMultiCamera:boolean;

  //camara activa para la visualización en tiempo real
  public camera:ECamera;

  //observables
  public onResizeCameraSubject = new Subject<void>();
  public connectCameraSubject = new Subject<void>();
  public disconnectCameraSubject = new Subject<void>();
  public changeCameraSubject = new Subject<ECamera>();

  onResizeCamera$ = this.onResizeCameraSubject.asObservable();
  connectCamera$ = this.connectCameraSubject.asObservable();
  disconnectCamera$ = this.disconnectCameraSubject.asObservable();
  changeCamera$ = this.changeCameraSubject.asObservable();

  showingInfo: boolean;//indica que el viewer está mostrando la pantalla de información previa al ejercicio

  constructor(private clientService: HttpClientService)
  {}

  /**
   * Obtiene la lista de cámaras conectadas al dispositivo
   * @returns Lista de cámaras conectadas
   */
  public GetCameras(): Promise<MediaDeviceInfo[]>
  {
    return new Promise<MediaDeviceInfo[]>((resolve, reject) =>
    {
      try
      {
        if ('mediaDevices' in navigator && navigator.mediaDevices)
        {
          navigator.mediaDevices.enumerateDevices().then((devices) =>
          {
            resolve(devices.filter(m => m.kind.indexOf('input') >= 0 && m.kind.indexOf('video') >= 0));
          });
        }
        else
        {
          reject();
        }
      }
      catch(ex)
      {
        reject();
      }
    });
  }

  /**
   * Busca la cámara principal de visualización
   */
  public SetStream()
  {
    /**
     * Este proceso se lleva a cabo una vez al inicio de la aplicación.
     * Previamente se ha bloqueado la cámara de tracking mediante una llamada a la librería de Nuavis.
     * Lo que hacemos es establecer como cámara principal de visualización la cámara que no haya sido bloqueada.
     * Previamente en un paso anterior nos hemos asegurado de que no haya conectada ninguna cámara auxiliar,
     * sólo las dos cámaras fijas.
     */

    //obtenemos las cámaras conectadas

    for (const camera of this.cameras)
    {
      this.mainConstraints.video.deviceId = { exact: camera.idDevice };

      //intentamos obtener el stream de cada cámara
      navigator.mediaDevices.getUserMedia(this.mainConstraints).then(stream =>
      {
        //console.log(cameraId + ' libre');
        //si la cámara está libre obtendremos el stream, es la cámara de visualización
        this.stream = stream;
        camera.function = (environment.production) ? ECameraFunction.VIEWER : camera.function;
      })
      .catch(error => {
        //console.log(cameraId + ' ocupada');
        //si la cámara está ocupada devolverá un error, es la cámara de tracking
        camera.function = (environment.production) ? ECameraFunction.TRACKING : camera.function;
      });
    }
  }

  /**
   * Devuelve el stream de la cámara principal de visualización actual
   * @returns MediaStream
   */
  public GetStream()
  {
    return this.stream;
  }

  // /**
  //  * Devuelve la lista de cámaras conectadas almacenada en una sesión local
  //  * @returns Lista de cámaras conectadas
  //  */
  // public GetSystemCameras()
  // {
  //   return JSON.parse(sessionStorage.getItem('winco.system_cameras'));
  // }

  public SetInitalCameras() : Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      this.GetCameras().then(devices =>
      {
        if(devices.length != environment.numCameras)
        {
          reject();
        }
        else
        {
          //obtenemos las cámaras del sistema
          this.cameras = [];

          for(let i = 0; i < devices.length; i++)
            this.cameras.push(new Camera({
              idDevice: devices[i].deviceId,
              type: ECamera.SYSTEM,
              function: (environment.production) ? ECameraFunction.UNKNOW : (i + 1),
              active: true
            }));

          //obtenemos la cámaras principales de visualización y tracking
          //bloqueamos la cámara que utiliza la librería Nuavis para el tracking
          //y nos quedeamos con la que queda libre
          this.LockCamera().then(() =>
          {
            this.SetStream();

            this.UnlockCamera().then(() =>
            {
              this.AddListenerCameras();
              this.systemCamerasConnected = true;

              resolve();
            },
            () => reject());
          },
          () => reject());
        }
      })
      .catch(() => reject());
    });
  }

  /**
   * Activa el manejador de eventos de dispositivos
   */
  public AddListenerCameras()
  {
    //añadimos un manejador de eventos al evento 'devicechange'
    //primero limpiamos el manejador para evitar duplicados
    this.RemoveListenerCameras();
    navigator.mediaDevices.ondevicechange = () => this.CheckCameras();
  }

  /**
   * Desactiva el manejador de eventos de dispositivos
   */
  public RemoveListenerCameras()
  {
    navigator.mediaDevices.ondevicechange = () => null;
  }

  private CheckCameras()
  {
    this.GetCameras().then(devices =>
    {
      const inactiveCameras = this.cameras.filter(m => m.type === ECamera.SYSTEM && !m.active);

      if(inactiveCameras.length > 0)
      {
        //comprobamos si se ha conectado una nueva camara
        inactiveCameras.map(camera =>
        {
          if(devices.find(m => m.deviceId === camera.idDevice))
          {
            if(camera.function === ECameraFunction.VIEWER)
            {
              //si es la cámara de visualización intentamos reconectar
              this.mainConstraints.video.deviceId = { exact: camera.idDevice };

              navigator.mediaDevices.getUserMedia(this.mainConstraints).then(stream =>
              {
                this.stream = stream;
                camera.active = true;
                this.CompleteCheckCameras(devices);
              });
            }
            else
            {
              camera.active = true;
              this.CompleteCheckCameras(devices);
            }
          }
        });
      }
      else
      {
        //comprobamos si las cámaras del sistema siguen conectadas
        //a veces se puede producir una desconexión espontánea
        this.cameras.filter(m => m.type === ECamera.SYSTEM).map(camera =>
        {
          if(!devices.find(m => m.deviceId === camera.idDevice))
            camera.active = false;
        });

        this.CompleteCheckCameras(devices);
      }
    });
  }

  /**
   * Obtiene la lista de cámaras conectadas al dispositivo
   * @returns Lista de cámaras
   */
  public RecoverCameras() : Promise<void>
  {
    return new Promise((resolve, reject) =>
    {
      this.GetCameras().then(devices =>
      {
        const inactiveCameras = this.cameras.filter(m => m.type === ECamera.SYSTEM && !m.active);

        if(inactiveCameras.length > 0)
        {
          //comprobamos si se ha conectado una nueva camara
          inactiveCameras.map(camera =>
          {
            if(devices.find(m => m.deviceId === camera.idDevice))
            {
              if(camera.function === ECameraFunction.VIEWER)
              {
                //si es la cámara de visualización intentamos reconectar
                this.mainConstraints.video.deviceId = { exact: camera.idDevice };

                navigator.mediaDevices.getUserMedia(this.mainConstraints).then(stream =>
                {
                  this.stream = stream;
                  camera.active = true;
                  this.CompleteCheckCameras(devices);

                  return (this.systemCamerasConnected) ? resolve() : reject();
                });
              }
              else
              {
                camera.active = true;
                this.CompleteCheckCameras(devices);

                return (this.systemCamerasConnected) ? resolve() : reject();
              }
            }
          });
        }
      });
    });
  }

  /**
   * Activa o desactiva la cámara auxiliar
   */
  private CompleteCheckCameras(devices: MediaDeviceInfo[])
  {
    this.systemCamerasConnected = this.cameras.find(m => m.type === ECamera.SYSTEM && !m.active) ? false : true;

    if(this.systemCamerasConnected)
      this.AddListenerCameras();

    const auxiliar = this.cameras.find(m => m.function === ECameraFunction.AUXILIAR);

    //si la cámara auxiliar estaba conectada
    if(auxiliar)
    {
      //comprobamos si se ha desconectado
      let disconnect = true;

      for (const device of devices)
        if(auxiliar.idDevice === device.deviceId)
          disconnect = false;

      if(disconnect)
      {
        const index = this.cameras.findIndex(m => m.function === ECameraFunction.AUXILIAR);

        if(index >= 0)
          this.cameras.splice(index, 1);

        this.hasMultiCamera = false;
        this.camera = ECamera.SYSTEM;

        this.disconnectCameraSubject.next();
      }
    }
    else
    {
      //si no hay cámara auxiliar conectada comprobamos si se ha conectado
      let idAuxiliarCamera = '';

      //si la camara conectada es distinta de las cámaras del sistema
      //la añadimos como cámara auxiliar
      for (const device of devices)
        if(!this.cameras.find(m => m.idDevice === device.deviceId))
          idAuxiliarCamera = device.deviceId;

      if(idAuxiliarCamera)
      {
        this.cameras.push(new Camera({
          idDevice: idAuxiliarCamera,
          type: ECamera.AUXILIAR,
          function: ECameraFunction.AUXILIAR,
          active: true
        }));

        this.hasMultiCamera = true;
        this.camera = ECamera.SYSTEM;

        this.connectCameraSubject.next();
      }
    }
  }

  /**
   * Redimensiona el visor de la cámara
   */
  public ResizeCamera()
  {
    this.onResizeCameraSubject.next();
  }

  /**
   * Cambia la vista de la cámara principal a la cámara auxiliar
   * @param camera ECamera - Cámara que debe ser mostrada
   */
  public ChangeCamera(camera:ECamera)
  {
    this.changeCameraSubject.next(camera);
  }

  /**
   * Inicia el tracking de un ejercicio
   * @returns Promise<any>
   */
  public StartAnalysis(): Promise<any>
  {
    return this.clientService.get("Camera/StartAnalysis");
  }

  /**
   * Finaliza el tracking de un ejercicio
   * @returns Promise<Replay> - Devuelve los datos obtenidos de trackeo
   */
  public StopAnalysis(save:boolean): Promise<Replay>
  {
    return this.clientService.get<Replay>("Camera/StopAnalysis/" + ((save) ? 'true' : 'false') );
  }

  /**
   * Pausa el tracking de un ejercicio
   * @returns Promise<any>
   */
  public PauseAnalysis(): Promise<any>
  {
    return this.clientService.get("Camera/PauseAnalysis");
  }

  /**
   * Reanuda el tracking de un ejercicio
   * @returns Promise<any>
   */
  public ResumeAnalysis(): Promise<any>
  {
    return this.clientService.get("Camera/ResumeAnalysis");
  }

  /**
   * Inicializa la librería de Nuavis
   * @returns Promise<any>
   */
  public InitializeAnalysis(): Promise<any>
  {
    return this.clientService.get("Camera/InitializeAnalysis");
  }

  /**
   * Detiene todos los procesos de la librería de Nuavis
   * @returns Promise<any>
   */
  public FinalizeAnalysis(): Promise<any>
  {
    return this.clientService.get("Camera/FinalizeAnalysis");
  }

  /**
   * Bloquea la cámara de tracking
   * @returns Promise<any>
   */
  public LockCamera(): Promise<any>
  {
    return this.clientService.get("Camera/LockCamera");
  }

  /**
   * Desbloquea la cámara de tracking
   * @returns Promise<any>
   */
  public UnlockCamera(): Promise<any>
  {
    return this.clientService.get("Camera/UnlockCamera");
  }

  /**
   * Inicia la grabación de un vídeo
   * @returns Promise<any>
   */
  public StartRecord(): Promise<any>
  {
    return this.clientService.get("Camera/StartRecord");
  }

  /**
   * Finaliza la grabación de un vídeo
   * @returns Promise<any>
   */
  public StopRecord(): Promise<any>
  {
    return this.clientService.get("Camera/StopRecord");
  }

  /**
   * Pausa la grabación de un vídeo
   * @returns Promise<any>
   */
  public PauseRecord(): Promise<any>
  {
    return this.clientService.get("Camera/PauseRecord");
  }

  /**
   * Reanuda la grabación de un vídeo
   * @returns Promise<any>
   */
  public ResumeRecord(): Promise<any>
  {
    return this.clientService.get("Camera/ResumeRecord");
  }
}
