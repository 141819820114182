import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpManagerService } from 'http-manager';
import { LanguageTabConfig, LanguageTabService } from 'language-tab';
import { TranslateService } from '@ngx-translate/core';
import { EventManagerService } from 'event-manager';

/**
 * Servicio que controla la gestión de idiomas
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageWincoService extends LanguageTabService
{
  //Observables
  private languageSource = new Subject<string>();

  languageSource$ = this.languageSource.asObservable();

  constructor(private httpManager: HttpManagerService,
              httpManagerService: HttpManagerService,
              translate: TranslateService,
              config: LanguageTabConfig,
              eventManagerService: EventManagerService)
  {
    super (httpManagerService, translate, eventManagerService, config)
  }

  /**
   * Almacena el idioma seleccionado en una sesión local
   * @param language Código ISO del idioma seleccionado
   */
  override setLanguage(language: string)
  {
    localStorage.setItem('winco.language', language);
    this.httpManager.setLanguage(language);
    this.languageSource.next(language);
  }
}
