import { IpcService } from 'src/app/providers/ipc.service';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { ExerciceService } from 'src/app/providers/exercice.service';
import { ETypeUser } from './enums/type-user.enum';
import { AuthWincoService } from 'src/app/providers/auth.service';
import { ETypeFooter } from './enums/type-footer.enum';
import { Component, ElementRef, HostListener, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ElectronService } from 'ngx-electron';
import { EViewerAside } from './enums/viewer-aside.enum';
import { BestExerciceDetailComponent } from './components/best-exercice-detail/best-exercice-detail.component';
import { CameraService } from './providers/camera.service';
import { ECamera } from './enums/camera.enum';
import { VideoService } from './providers/video.service';
import { HttpClientService } from './providers/http-client.service';
import { CrudManagerService } from 'crud-manager';
import { DialogBoxData, DialogBoxService, DialogBoxType } from 'dialog-box';
import { VersionBoxService } from 'version-box';
import { LanguageTab } from 'language-tab';
import { LanguageWincoService } from './providers/language.service';
import { EventManagerService } from 'event-manager';
import { EventManagerEvent } from './enums/event-manager.enum';
import { WincoUser } from './models/winco/user.model';
import { BetterScore } from './models/winco/better-score.model';

/**
 * Componente que represanta la base de la aplicación
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent
{
  TypeFooterEnum = ETypeFooter;
  ETypeUserEnum = ETypeUser;
  EViewerAsideEnum = EViewerAside;
  ECamera = ECamera;

  @ViewChildren(InfoBoxComponent) infoBox!: QueryList<InfoBoxComponent>;
  @ViewChild('exerciceDetail', {read: BestExerciceDetailComponent, static: false}) exerciceDetail: BestExerciceDetailComponent;
  @ViewChild('header', {read: ElementRef}) header: ElementRef;

  //configuración para el componente de ayuda
  helpViewer =
  [
    {
      name: 'infoMenu',
      class: 'info-menu',
      type: 'none'
    },
    {
      name: 'infoInstructions',
      class: 'info-instructions',
      type: 'none'
    },
    {
      name: 'infoVideo',
      class: 'info-video',
      type: 'info'
    },
    {
      name: 'infoPlayer',
      class: 'info-player',
      type: 'player'
    },
    {
      name: 'infoTimer',
      class: 'info-timer',
      type: 'player'
    }
  ];;

  //variables para el control de idiomas
  nameLanguage: string;
  showLanguages = false;
  activeLanguage: string;
  availableLanguages: LanguageTab[];

  //variables para el control de carga de páginas
  isInitPage: boolean;
  isMenuPage: boolean;
  isExercicePage: boolean;

  hasMenuAdmin: boolean;
  hasMenuNav: boolean;
  hasFooter: boolean;
  hasMenuAction: boolean;
  typeFooter: ETypeFooter;
  showResultExerciceButtons:boolean;
  showExerciceDetail:boolean;

  userAcronym: string; //acrónimo de usuario
  userName: string; //nombre completo de usuario
  userRol: ETypeUser; //rol de usuario

  //variables para el control del componente Aside
  aside: EViewerAside;
  returnAside:EViewerAside;

  //variables para el control de reproducción
  hasVideoButton:boolean;
  // hasMultiCamera:boolean;
  // zoomPoster: string;
  // zoomVideo: string;

  //camara activa para la visualización en tiempo real
  // camera:ECamera;

  betterScore:number;
  betterTime:string;

  //variables para la composición de urls de navegación
  type:string;
  uuidExercice:string;
  uuidExerciceHistory: string | undefined;
  uuidCourse:string;
  uuidUser:string

  startedExercice = false; //indica si se está reproduciendo un ejercicio
  trackingActive: boolean = false;

  anno: number; //año actual

  constructor(private translateService:TranslateService,
              private languageService:LanguageWincoService,
              private authService:AuthWincoService,
              private router:Router,
              private ipcService: IpcService,
              private electronService: ElectronService,
              private exerciceService: ExerciceService,
              private videoService: VideoService,
              private ref: ElementRef,
              private versionBoxService: VersionBoxService,
              private httpClientService: HttpClientService,
              private crudService:CrudManagerService,
              private dialogService: DialogBoxService,
              private eventManagerService: EventManagerService,
              public cameraService: CameraService)
  {
    this.httpClientService.initialize();
    this.crudService.init(this.httpClientService);

    this.anno = new Date().getFullYear();

    //gestión de carga de páginas
    //controla la visualización de los distintos elementos que
    //componen un pantalla en función de la url que deba ser cargada
    this.router.events.subscribe((routerData) =>
    {
      if(routerData instanceof ResolveEnd)
      {
        this.isInitPage = false;
        this.isMenuPage = false;
        this.isExercicePage = false;
        this.hasMenuAdmin = false;
        this.hasMenuNav = false;
        this.hasFooter = false;
        this.typeFooter = ETypeFooter.CLEAR;
        this.showResultExerciceButtons = false;

        this.videoService.stopVideo();

        if(routerData.url.indexOf('account') >= 0)
        {
          this.hasFooter = true;
        }
        else if(routerData.url.indexOf('administration') >= 0)
        {

          // if(routerData.url.indexOf('list') >= 0)
          //   this.exerciceService.ClearForms('list');
          // else if(routerData.url.indexOf('edit') >= 0)
          //   this.exerciceService.ClearForms('edit');
          // else
          this.exerciceService.ClearForms();

          this.isMenuPage = true;
          this.hasMenuAdmin = true;
          this.hasMenuNav = true;

          if(routerData.url.indexOf('edit') >= 0 )
          {
            this.hasFooter = false;
            this.typeFooter = ETypeFooter.CLEAR;
          }
          else
          {
            this.hasFooter = true;
            this.typeFooter = ETypeFooter.LINE;
          }
        }
        else if(routerData.url.indexOf('profile') >= 0)
        {
          this.isMenuPage = true;
          this.hasMenuNav = true;
          this.hasFooter = false;
          this.typeFooter = ETypeFooter.CLEAR;
        }
        else if(routerData.url.indexOf('exercice') >= 0)
        {
          if(routerData.url.indexOf('viewer') >= 0)
            this.exerciceService.ClearExercice('viewer');
          else if(routerData.url.indexOf('statistics') >= 0)
            this.exerciceService.ClearExercice('statistics');
          else
            this.exerciceService.ClearExercice();

          this.isMenuPage = true;
          this.hasMenuNav = true;
          this.hasFooter = (routerData.url.indexOf('checklist') < 0);
          this.typeFooter = ETypeFooter.LINE;

          if(routerData.url.indexOf('viewer') >= 0 || routerData.url.indexOf('statistics') >= 0)
          {
            this.isExercicePage = true;
            this.hasMenuAction = true;

            if(routerData.url.indexOf('statistics') >= 0)
              this.showResultExerciceButtons = true;
          }
        }
        else if(routerData.url.indexOf('statistics') >= 0 ||
                routerData.url.indexOf('export') >= 0 ||
                routerData.url.indexOf('update') >= 0 ||
                routerData.url.indexOf('history') >= 0 ||
                routerData.url.indexOf('courses') >= 0 ||
                routerData.url.indexOf('online') >= 0)
        {
          this.isMenuPage = true;
          this.hasMenuNav = true;
          this.hasFooter = (routerData.url.indexOf('statistics') < 0);
          this.typeFooter = ETypeFooter.LINE;
        }
        else if(routerData.url.indexOf('errors') >= 0)
        {
          this.isMenuPage = false;
          this.hasMenuNav = false;
          this.hasFooter = false;
          this.isExercicePage = false;
          this.hasMenuAction = false;
        }
        else
        {
          this.isInitPage = true;
        }
      }
    });

    //carga el idioma por defecto
    this.activeLanguage = this.languageService.getLanguage() ?? environment.defaultLanguage;

    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.languageService.setLanguage(this.activeLanguage);
    this.translateService.use(this.activeLanguage);

    this.languageService.getAvailableLanguages().subscribe(response =>
    {
      this.availableLanguages = response;

      const language = this.availableLanguages.find(x => x.code === this.activeLanguage);

      language.enabled = false;
      this.nameLanguage = language.code.toUpperCase();
    });


    //informa de si el componente ViewerAside se está mostrando o no
    this.eventManagerService.subscribeEvent<EViewerAside>(EventManagerEvent.SET_BUTTONS, (data:EViewerAside) =>
    {
      if(data == EViewerAside.CLOSE_HELP)
      {
        this.aside = this.returnAside;
      }
      else
      {
        if(data != EViewerAside.HELP)
          this.returnAside = data;

        this.aside = data;
      }
    });

    //informa de si el deben mostrarse los botones de control de vídeo o no
    this.eventManagerService.subscribeEvent<boolean>(EventManagerEvent.SHOW_VIDEO_BUTTON, (show:boolean) =>
    {
      this.hasVideoButton = show;
      this.returnAside = (show) ? EViewerAside.VIDEO : EViewerAside.GUIDE;
    });

    //informa de si debe mostrarse el porcentaje de mejor puntuación cuando hay o no tracking
    this.eventManagerService.subscribeEvent<boolean>(EventManagerEvent.TRACKING_CHECK, (show:boolean) => this.trackingActive = show);


    //actualiza los valores que se muestran en el pie (betterScore y betterTime)
    //actualiza los uuid de todos los valores necesarios para crear las url de retorno
    this.eventManagerService.subscribeEvent<BetterScore>(EventManagerEvent.ON_UPDATE_BETTER, (data:BetterScore) =>
    {
        this.betterScore = data.score;
        this.betterTime = this.exerciceService.GetFormatTime(data.time);
        this.uuidExercice = data.uuidExercice;
        this.uuidExerciceHistory = data.uuidExerciceHistory;
        this.uuidCourse = data.uuidCourse;
        this.uuidUser = data.uuidUser;
        this.type = data.type;
    });

    //actualiza los datos que se muestran del usuario
    this.eventManagerService.subscribeEvent<WincoUser>(EventManagerEvent.ENABLE_USER, (user:WincoUser) =>
    {
      this.userAcronym = (user.name[0] + user.surname[0]).toUpperCase();
      this.userName = user.name + ' ' + user.surname;
      this.userRol = user.idType;
    });

    //actualiza los datos de la vista de detalle de un ejercicio
    //y muestra el componente ExerciceDetail
    this.eventManagerService.subscribeEvent<any>(EventManagerEvent.SHOW_BEST_EXERCISE, (data:any) =>
    {
      this.showExerciceDetail = true;

      setTimeout(() =>
      {
        this.exerciceDetail.time = data.time;
        this.exerciceDetail.score = data.score;
        this.exerciceDetail.userName = data.userName;
        this.exerciceDetail.urlPoster = data.urlPoster;
        this.exerciceDetail.urlVideo = data.urlVideo;
        this.exerciceDetail.params = data.params;
      }, environment.delayOperation);
    });

    //limpia los datos de la vista de detalle de un ejercicio
    //y oculta el componente ExerciceDetail
    this.eventManagerService.subscribeEvent<void>(EventManagerEvent.HIDE_BEST_EXERCISE, () =>
    {
      this.showExerciceDetail = false;

      this.exerciceDetail.time = null;
      this.exerciceDetail.score = null;
      this.exerciceDetail.userName = null;
      this.exerciceDetail.urlPoster = null;
      this.exerciceDetail.urlVideo = null;
      this.exerciceDetail.params = null;
    });

    // //notificación de que la cámara auxiliar está conectada
    // this.cameraService.connectCamera$.subscribe(() =>
    // {
    //   this.hasMultiCamera = true;
    //   this.camera = ECamera.SYSTEM;
    //   console.log('hasMultiCamera', this.hasMultiCamera)
    // });

    // //notificación de que la cámara auxiliar está desconectada
    // this.cameraService.disconnectCamera$.subscribe(() =>
    // {
    //   this.hasMultiCamera = false;
    //   this.camera = ECamera.SYSTEM;
    //   console.log('hasMultiCamera', this.hasMultiCamera)
    // });

    //notificación de que se ha comenzado a realizar un ejercicio
    this.eventManagerService.subscribeEvent<boolean>(EventManagerEvent.START_EXERCISE, (status:boolean) => this.startedExercice = status);

    //captura del evento beforeunload
    window.addEventListener('beforeunload', async () =>
    {
      this.clearUser();
      await this.cameraService.FinalizeAnalysis();
    });


    this.ipcService.initializeData().then(() =>
    {
      this.versionBoxService.initialize(this.ipcService.info.software, this.ipcService.info.hardware);

      //obtenemos los datos del usuario
      const user = authService.getUser();

      //si existe usuario actualizamos los datos de visualización
      if(user)
      {
        this.userAcronym = (user.name[0] + user.surname[0]).toUpperCase();
        this.userName = user.name + ' ' + user.surname;
        this.userRol = user.idType;
      }
    }).catch(() => this.router.navigate([ '/', 'error' ]));
  }

  /**
   * Se ejecuta la primera vez que se carga la página
   */
  ngOnInit(): void
  {
    this.onResize();
  }

  /**
   * Se ejecuta cada vez que se carga la vista
   */
  ionViewWillEnter()
  {
    this.showExerciceDetail = false;
  }

  /**
   * Se ejecuta cada vez que se abandona la vista
   */
  ionViewWillLeave()
  {
    this.showExerciceDetail = false;
  }

/**
 * Redimensiona el tamaño de fuente en función del tamaño de la ventana
 * permitiendo que los estilos css basados en medidas relativas (em, rem)
 * se auto-ajusten según cambia el tamaño de la ventana
 */
  @HostListener('window:resize')
  onResize() {
    const minWidth = 480,
          minHeight = 320,
          minSize = 5,
          maxSize = 16;

    let windowWidth:number = window.innerWidth,
        windowHeight:number = window.innerHeight,
        width:number,
        height:number,
        size:number;

    if(windowWidth < minWidth) windowWidth = minWidth;
    if(windowHeight < minHeight) windowHeight = minHeight;

    if(windowWidth > windowHeight)
    {
      width = windowWidth;
      height = windowWidth * minHeight / minWidth;

      if(height > windowHeight)
      {
        height = windowHeight;
        width = windowHeight * minWidth / minHeight;
      }
    }
    else
    {
      height = windowHeight;
      width = windowHeight * minWidth / minHeight;

      if(width > windowWidth)
      {
        width = windowWidth;
        height = windowWidth * minHeight / minWidth;
      }
    }

    size = Math.round(height * minSize / minHeight);

    if(size > maxSize) size = maxSize;

    this.ref.nativeElement.style.fontSize = size + 'px';
  }


  /**
  * Muestra u oculta la lista de idiomas disponibles
  * @param e Event
  */
  toggleLanguages(e:Event)
  {
    e.preventDefault();
    this.showLanguages = (!this.showLanguages && this.availableLanguages.filter(x => x.enabled).length > 0);
  }

  /**
   * Establece un idioma seleccionado desde la lista de idiomas
   * @param e Event
   * @param language Código de idioma ISO
   */
  selectLanguage(e:Event, language:string)
  {
    e.preventDefault();

    this.activeLanguage = language;
    this.languageService.setLanguage(language);
    this.translateService.use(language);

    this.availableLanguages?.map((item) => { item.enabled = true; });

    const lang = this.availableLanguages?.find(x => x.code === this.activeLanguage);

    lang.enabled = false;
    this.nameLanguage = lang.code.toUpperCase();

    this.showLanguages = false;
  }

  /**
   * Comprueba si la opción del menú lateral está seleccionada actualmente
   * @param elem Nombre de la página
   * @param ignore Indica si algún componente de la url debe ser ignorado
   * @returns Boolean
   */
  getMenuSelected(elem:string, ignore:string = '') : boolean
  {
    if(ignore && this.router.url.indexOf(ignore) >= 0)
      return false;

    return this.router.url.indexOf(elem) >= 0;
  }

  /**
   * Comprueba si un usuario tiene permiso para ver un apartado del menú
   * @param roles Lista de roles permitidos para el apartado
   * @returns Boolean
   */
  hasPermissions(roles:ETypeUser[])
  {
    return (roles.indexOf(this.userRol) >= 0)
  }

  /**
   * Cambia el contenido del componente ViewerAside del lateral derecho de la pantalla
   * @param aside EViewerAside - Tipo de aside
   */
  changeRightContent(aside:EViewerAside)
  {
    this.eventManagerService.emitEvent(EventManagerEvent.SET_ASIDE, aside);
  }

  /**
   * Cambia la vista de un slider de la pantalla de ayuda
   * @param ev CustomEvent
   */
  changeViewSlider(ev: any)
  {
    this.infoBox.map((info: InfoBoxComponent) => info.isOpen = (ev.detail == info.name) ? !info.isOpen : false);
  }

  /**
   * Cambia la vista de la cámara cuando la cámara auxiliar está conectada
   * @param camera ECamera - Cámara seleccionada
   */
  changeCamera(camera: ECamera)
  {
    if(this.cameraService.camera != camera || camera === ECamera.BOTH)
    {
      this.cameraService.camera = camera;
      this.cameraService.ChangeCamera(camera);
    }
  }

  /**
   * Recarga la pantalla de un ejercicio que el usuario ha terminado y desea repetir
   */
  repeatExercice()
  {
    this.router.navigate(['/', 'exercice', 'viewer', this.uuidExercice, this.type, this.uuidCourse, this.uuidExerciceHistory, this.uuidUser]);
  }

  /**
   * Cierra la ventana de detalle de un ejercicio
   */
  closeExerciceDetail()
  {
    this.showExerciceDetail = false;
  }

  /**
   * Fuerza el cierre de un ejercicio que aún está en proceso
   */
  goOut()
  {
    let route = [];

    switch(this.type)
    {
      case 'course' : route = [ '/', 'courses', 'detail', this.uuidCourse ]; break;
      case 'history' : route = [ '/', 'history', 'history', this.uuidExerciceHistory, this.uuidUser, this.uuidCourse ]; break;
      default: route = [ '/', 'exercice', 'type' ];
    }

    if(this.startedExercice)
      this.eventManagerService.emitEvent(EventManagerEvent.CLOSE_EXERCISE, route);
    else
      this.router.navigate(route);

    this.uuidUser = '';
    this.uuidExercice = '';
    this.uuidExerciceHistory = '';
    this.uuidCourse = '';
    this.type = '';
  }

  /**
   * Cierra la sesión del usuario actual
   */
  logOut(){
    this.dialogService.show(new DialogBoxData({
      type: DialogBoxType.CONFIRM,
      message: 'seguro_cerrar_sesion',
      acceptCallback: () =>
      {
        this.clearUser();
        this.router.navigate([ '/', 'account', 'login' ]);
      }
    }));
  }

  /**
   * Elimina los datos del usuario actual
   */
  clearUser()
  {
    this.authService.removeUser();
    this.userAcronym = '';
    this.userName = '';
    this.userRol = null;
  }

  /**
   * Cierra el programa
   */
  exit()
  {
    if(this.electronService.isElectronApp)
    {
      this.dialogService.show(new DialogBoxData({
        type: DialogBoxType.CONFIRM,
        message: 'seguro_salir',
        acceptCallback: () =>
        {
          this.clearUser();
          this.cameraService.FinalizeAnalysis().then();
          this.ipcService.quitApp();
        }
      }));
    }
  }

  /**
   * Muestra una pantalla con la versión del software actual
   */
  showInfoVersion()
  {
    this.versionBoxService.show()
  }
}
