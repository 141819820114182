import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ETypeUser } from './enums/type-user.enum';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'errors/error/:id',
    loadChildren: () => import('./pages/errors/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'errors/camera',
    loadChildren: () => import('./pages/errors/camera/camera.module').then( m => m.CameraPageModule)
  },
  {
    path: 'account/login',
    loadChildren: () => import('./pages/account/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'account/recover',
    loadChildren: () => import('./pages/account/recover/recover.module').then( m => m.RecoverPageModule)
  },
  {
    path: 'account/new-account',
    loadChildren: () => import('./pages/account/new-account/new-account.module').then( m => m.NewAccountPageModule)
  },
  {
    path: 'administration/groups/list',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/groups/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'administration/groups/edit/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/groups/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'administration/teachers/list',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN ] },
    loadChildren: () => import('./pages/administration/teachers/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'administration/teachers/edit/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN ] },
    loadChildren: () => import('./pages/administration/teachers/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'administration/students/list',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/students/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'administration/students/edit/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/students/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'administration/exercices/list',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/exercices/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'administration/exercices/edit/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/exercices/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'administration/courses/list',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/courses/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'administration/courses/edit/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/administration/courses/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'statistics',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/statistics/statistics.module').then( m => m.StatisticsPageModule)
  },
  {
    path: 'online/viewer',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/online/viewer/viewer.module').then( m => m.ViewerPageModule)
  },
  {
    path: 'online/rooms',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/online/rooms/rooms.module').then( m => m.RoomsPageModule)
  },
  {
    path: 'online/students',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/online/students/students.module').then( m => m.StudentsPageModule)
  },
  {
    path: 'online/help/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/online/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'online/card/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/online/card/card.module').then( m => m.CardPageModule)
  },
  {
    path: 'online/check-list/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/online/check-list/check-list.module').then( m => m.CheckListPageModule)
  },
  {
    path: 'export',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/export/export.module').then( m => m.ExportPageModule)
  },
  {
    path: 'update',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'exercice/type',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT ] },
    loadChildren: () => import('./pages/exercice/type/type.module').then( m => m.TypePageModule)
  },
  {
    path: 'exercice/list/:id/:uuid',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT ] },
    loadChildren: () => import('./pages/exercice/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'exercice/detail/:id/:type/:uuid',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT ] },
    loadChildren: () => import('./pages/exercice/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'exercice/viewer/:id',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT ] },
    loadChildren: () => import('./pages/exercice/viewer/viewer.module').then( m => m.viewerPageModule)
  },
  {
    path: 'exercice/viewer/:id/:type/:course/:exercice/:student',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/exercice/viewer/viewer.module').then( m => m.viewerPageModule)
  },
  {
    path: 'exercice/viewer/:id/:type/:course',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/exercice/viewer/viewer.module').then( m => m.viewerPageModule)
  },
  {
    path: 'exercice/checklist/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/exercice/checklist/checklist.module').then( m => m.ChecklistPageModule)
  },
  {
    path: 'exercice/checklist/:id/:type/:course',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/exercice/checklist/checklist.module').then( m => m.ChecklistPageModule)
  },
  {
    path: 'exercice/checklist/:id/:type/:course/:exercice/:student',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/exercice/checklist/checklist.module').then( m => m.ChecklistPageModule)
  },
  {
    path: 'exercice/statistics/:id',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUserEnum.ADMIN, ETypeUserEnum.TEACHER, ETypeUserEnum.STUDENT ] },
    loadChildren: () => import('./pages/exercice/statistics/statistics.module').then( m => m.StatisticsPageModule)
  },
  {
    path: 'exercice/statistics/:id/:type/:course',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/exercice/statistics/statistics.module').then( m => m.StatisticsPageModule)
  },
  {
    path: 'exercice/statistics/:id/:type/:course/:exercice/:student',
    //canActivate: [AuthGuard],
    //data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/exercice/statistics/statistics.module').then( m => m.StatisticsPageModule)
  },
  {
    path: 'history/history',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/history/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'history/history/:exercice/:user/:course',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/history/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'history/detail/:id/:course/:exercice/:user',
    loadChildren: () => import('./pages/history/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'courses/list',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/courses/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'courses/detail/:id',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER, ETypeUser.STUDENT ] },
    loadChildren: () => import('./pages/courses/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'courses/detail/:id/:student',
    canActivate: [AuthGuard],
    data: { roles : [ ETypeUser.ADMIN, ETypeUser.TEACHER ] },
    loadChildren: () => import('./pages/courses/detail/detail.module').then( m => m.DetailPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
