import { ExerciceService } from 'src/app/providers/exercice.service';
import { Component, ElementRef, Input, OnChanges, ViewChild, Renderer2 } from '@angular/core';

/**
 * Muestra un selector de valores de parámetros de ejercicio en la pantalla de edición de ejercicio
 */
@Component({
  selector: 'app-exercice-counter-component',
  templateUrl: './exercice-counter.component.html',
  styleUrls: ['./exercice-counter.component.scss'],
})
export class ExerciceCounterComponent implements OnChanges {

  @Input() value: number | undefined;
  @Input() type: string | undefined;
  @Input() field: string | undefined;
  @Input() param: string | undefined;

  @ViewChild('minutesBox') minutesBox : ElementRef;
  @ViewChild('secondsBox') secondsBox : ElementRef;

  show:string | undefined;
  top = (99 * 60) + 59;
  minutes:string;
  seconds:string;
  mode = 'counter';
  changeButtonBlocked = false;

  constructor(private ref:ElementRef,
              private exerciceService: ExerciceService,
              private renderer:Renderer2)
  {}

  /**
   * Se ejecuta cuando se producen cambios en la vista
   */
  ngOnChanges()
  {
    if(!this.value)
      this.value = 0;

    this.convert();
  }

  /**
   * Convierte un valor en su representación correcta en función si se muestra tiempo o un valor absoluto
   */
  convert()
  {
    this.show = (this.type == 'time') ? this.exerciceService.GetFormatTime(this.value) : this.value.toString();
  }

  /**
   * Resta un valor al contador
   */
  subtract()
  {
    const val = (this.type == 'time') ? 10 : 1;
    this.value -= val;

    if(this.value < 0)
      this.value = 0;

    this.convert();

    this.ref.nativeElement.dispatchEvent(new CustomEvent('changecounter', { bubbles: true, detail: {field: this.field, param: this.param, value: this.value} }));
  }

  /**
   * Suma un valor al contador
   */
  sum()
  {
    const val = (this.type == 'time') ? 10 : 1;
    this.value += val;

    if(this.value > this.top)
      this.value = this.top;

    this.convert();

    this.ref.nativeElement.dispatchEvent(new CustomEvent('changecounter', { bubbles: true, detail: {field: this.field, param: this.param, value: this.value} }));
  }

  /**
   * Cambia el modo de edición del valor del componente
   * @param mode
   */
  changeView(mode:string)
  {
    this.mode = mode;

    if(mode == 'free')
    {
      this.minutes = this.show.split(':')[0];
      this.seconds = this.show.split(':')[1];
    }
  }

  /**
   * Valida el valor del componente
   * @param ev Event
   */
  keyUpBox(ev:any, box:string)
  {
    let val:number;
    let error:boolean;

    if(this.type == 'time')
    {
      let minutes = Number(this.minutesBox.nativeElement.value);
      let seconds = Number(this.secondsBox.nativeElement.value);

      this.renderer.removeClass(this.minutesBox.nativeElement, 'danger');
      this.renderer.removeClass(this.secondsBox.nativeElement, 'danger');

      if(isNaN(minutes) || minutes < 0)
      {
        error = true;
        this.renderer.addClass(this.minutesBox.nativeElement, 'danger');
      }

      if(isNaN(seconds) || seconds < 0)
      {
        error = true;
        this.renderer.addClass(this.secondsBox.nativeElement, 'danger');
      }
      else if(seconds > 59)
      {
        seconds = 59;
        this.secondsBox.nativeElement.value = seconds;
      }

      this.value = (error) ? this.value : (minutes * 60) + seconds;
    }
    else
    {
      val = Number(ev.target.value);

      if(isNaN(val) || val < 0)
      {
        error = true;
        this.renderer.addClass(this.minutesBox.nativeElement, 'danger');
      }
      else
      {
        this.value = val;
      }
    }

    this.changeButtonBlocked = error;
    this.show = (this.type == 'time') ? this.exerciceService.GetFormatTime(this.value) : this.value.toString();
    this.ref.nativeElement.dispatchEvent(new CustomEvent('changecounter', { bubbles: true, detail: {field: this.field, param: this.param, value: this.value} }));
  }
}
