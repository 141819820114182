import { VideoComponent } from './../video/video.component';
import { ExerciceService } from './../../providers/exercice.service';
import { Exercice } from './../../models/winco/exercice.model';
import { Component, Input, ViewChild } from '@angular/core';
import { EventManagerService } from 'event-manager';
import { EventManagerEvent } from 'src/app/enums/event-manager.enum';

/**
 * Muestra el detalle de un ejercicio
 */
@Component({
  selector: 'app-best-exercice-detail-component',
  templateUrl: './best-exercice-detail.component.html',
  styleUrls: ['./best-exercice-detail.component.scss'],
})
export class BestExerciceDetailComponent
{
  @Input() exercice: Exercice;
  @Input() userName:string;
  @Input() time: number;
  @Input() score:number;
  @Input() params:any[];
  @Input() urlPoster:string;
  @Input() urlVideo:string;

  @ViewChild('video') video : VideoComponent;

  constructor(private eventManagerService: EventManagerService,
              public exerciceService:ExerciceService) { }

  /**
   * Detiene la reproducción del vídeo y oculta el detalle
   */
  close()
  {
    this.video.stop();
    this.eventManagerService.emitEvent(EventManagerEvent.HIDE_BEST_EXERCISE);
  }
}
