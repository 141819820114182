import { TranslateModule } from '@ngx-translate/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from './video.component';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [VideoComponent],
  declarations: [VideoComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class VideoComponentModule {}
