export enum EventManagerEvent
{
  SET_ASIDE = 'WINCO_EXERCISE_SET_ASIDE',
  SET_BUTTONS = 'WINCO_EXERCISE_SET_BUTTONS',
  SHOW_VIDEO_BUTTON = 'WINCO_EXERCISE_SHOW_VIDEO_BUTTON',
  ON_UPDATE_BETTER = 'WINCO_EXERCISE_ON_UPDATE_BETTER',
  SHOW_BEST_EXERCISE = 'WINCO_EXERCISE_SHOW_BEST_EXERCISE',
  HIDE_BEST_EXERCISE = 'WINCO_EXERCISE_HIDE_BEST_EXERCISE',
  CLOSE_EXERCISE = 'WINCO_EXERCISE_CLOSE_EXERCISE',
  START_EXERCISE = 'WINCO_EXERCISE_START_EXERCISE',
  TRACKING_CHECK = 'WINCO_EXERCISE_TRACKING_CHECK',
  GET_BETTER_SCORE = 'WINCO_EXERCISE_GET_BETTER_SCORE',

  GET_EXERCISE_BY_UUID = 'WINCO_LIST_GET_EXERCISE_BY_UUID',

  ENABLE_USER = 'WINCO_AUTH_ENABLE_USER', //enableUserSubject
}
